import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';
import qs from 'querystring';

import 'antd/dist/antd.css';
import 'font-awesome/css/font-awesome.css';
import 'simple-line-icons/css/simple-line-icons.css';
import 'react-block-ui/style.css';

import './assets/css/main.css';
import './assets/css/pages.css';
import './assets/css/authenticate.css';
import './assets/css/custom.css';

import App from './App';
import configureStore from './store';
import {
	getUserID,
	httpRequest,
	baseURL,
	userAPI,
	settingKey,
	authKey,
	pixelID,
} from './config/constants';
import { setCurrentUser, stopPreloading, setUserData } from './actions/user';
import { putSettings } from './actions/settings';
import history from './history';
import { doNotify } from './actions/general';
import * as serviceWorker from './serviceWorker';

ReactPixel.init(pixelID, {}, { debug: true, autoConfig: false });
ReactPixel.pageView();

const store = configureStore();

const checkResetToken = async () => {
	const location = history.location.pathname.split('/');
	if (location.length > 1 && location[1] === 'reset-password') {
		try {
			const url = `${baseURL}/auth/check-token/${location[2]}`;
			const rs = await httpRequest(url, 'GET', false);
			if (rs.reset) {
				store.dispatch(setUserData(null, rs.reset.email));
			} else {
				store.dispatch(stopPreloading());
				history.push('/page-not-found');
			}
		} catch (e) {
			store.dispatch(stopPreloading());
			history.push('/page-not-found');
		}
	}
};

const checkVerifyAccount = async () => {
	const location = history.location.pathname.split('/');
	if (location.length > 1 && location[1] === 'activate') {
		try {
			const rs = await httpRequest(
				`${baseURL}/auth/verify-email/${location[2]}`,
				'GET',
				false
			);
			localStorage.setItem(authKey, rs.token);
		} catch (e) {
			const message = e.message || 'invalid activation token';
			store.dispatch(stopPreloading());
			history.push(`/?e=1&q=${message}`);
		}
	}
};

const checkUser = async () => {
	await checkResetToken();
	await checkVerifyAccount();

	const query = qs.parse(history.location.search.replace('?', ''));
	let string = '';
	if (query.fcl) {
		string = `&fcl=${query.fcl}`;
	}

	const userID = getUserID();

	if (userID) {
		try {
			const location = history.location.pathname.split('/');
			const rs = await httpRequest(
				`${baseURL}${userAPI}/${userID}?platform=user`,
				'GET',
				true
			);
			store.dispatch(setCurrentUser(rs.user));
			store.dispatch(putSettings(rs.settings));
			store.dispatch(stopPreloading());
			document.body.className = 'pages';
			if (
				history.location.pathname === '/' ||
				(location.length > 1 && location[1] === 'activate')
			) {
				history.push('/dashboard');
			}
		} catch (error) {
			console.log(error);

			localStorage.removeItem(authKey);
			localStorage.removeItem(settingKey);
			store.dispatch(stopPreloading());
			history.push(`/?not-authenticated${string}`);
		}
	} else {
		const location = history.location.pathname.split('/');
		const query = qs.parse(history.location.search.replace('?', ''));
		const activate = location[1] !== 'activate' && query && query.e === '1';
		if (
			location.length > 1 &&
			location[1] !== 'forgot-password' &&
			location[1] !== 'reset-password' &&
			!activate
		) {
			history.push(`/?not-authenticated${string}`);
		}
		if (location.length > 1 && location[1] === 'setup') {
			history.push(`/?reset${string}`);
		}
		store.dispatch(stopPreloading());
		setTimeout(() => {
			if (activate) {
				store.dispatch(
					doNotify({ message: query.q, level: 'error', title: '' })
				);
			}
		}, 1000);
	}
};

checkUser();

ReactDOM.render(
	<Provider store={store}>
		<Router history={history}>
			<App />
		</Router>
	</Provider>,
	document.getElementById('main')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
